const allQuotes = [
  {
    Person: 'Susan H.',
    Location: 'MILFORD, CT',
    Quote: 'This first order was OUTSTANDING! I will never buy meat in the grocery store again! The way to describe the food is ... Clean!  And I love the individual packaging!',
    Avatar: 'sprite-cust_1'
  },
  {
    Person: 'Dylan F.',
    Location: 'Foster City, CA',
    Quote: 'You guys just made my life great... When meat shortages were at an all time high and it was impossible to find ANYTHING in the store you guys came through not only with meat but USDA prime choice meats. You can taste the difference.',
    Avatar: 'sprite-cust_2'
  },
  {
    Person: 'Barbara P.',
    Location: 'CLYDE, NC',
    Quote: 'My family and I were very impressed with the quality and taste of everything in our package. We commend you on your efforts to bring such great products that even a family can afford. We look forward to many more packages...',
    Avatar: 'sprite-cust_5'
  },
  {
    Person: 'Susan N.',
    Location: 'SAN JOSE, CA',
    Quote: 'Wow, better than the other meat delivery services. Not only is the meat the best, but the packaging makes it so it doesn’t leak in my fridge when thawing... Other delivery services don’t give me that level of confidence.',
    Avatar: 'sprite-cust_7'
  },
  {
    Person: 'Tracy C.',
    Location: 'CLARKSTON, MI',
    Quote: 'We’ve been so pleased with everything we’ve received so far! Your meat and seafood have been delicious! So convenient and great quality, so glad I stumbled upon your company! Can’t wait for our next order.',
    Avatar: 'sprite-cust_8'
  },
  {
    Person: 'Paul R.',
    Location: 'WAGRAM, NC',
    Quote: 'The red snapper and the 13 oz rib eyes in our first order were OUTSTANDING ... we look forward to our next box!',
    Avatar: 'sprite-cust_6'
  },
  {
    Person: 'Ester G.',
    Location: 'SIERRA MADRE, CA',
    Quote: 'It’s been a very good experience! So far, I love the orders I have received. Thank you, keep up the good work!',
    Avatar: 'sprite-cust_4'
  },
  {
    Person: 'Brandi S.',
    Location: 'EUREKA, MO',
    Quote: 'I love this service! It has been a real life saver during these difficult times :)',
    Avatar: 'sprite-cust_10'
  },
  {
    Person: 'Daniel M.',
    Location: 'SAN DIEGO, CA',
    Quote: 'The pork chops in my first pack were UNBELIEVABLE!! Keep\'em coming!',
    Avatar: 'sprite-cust_3'
  },
  {
    Person: 'Jodie Y.',
    Location: 'GREAT FALLS, MT',
    Quote: 'This is fabulous!!',
    Avatar: 'sprite-cust_9'
  },
  {
    Person: 'Geri Y.',
    Location: 'ELBURN, IL',
    Quote: 'Love SteakChop! ... Your petite tenders are phenomenal!',
    Avatar: 'sprite-cust_11'
  }
  /*
  {
    Person: 'Deborah D.',
    Location: 'PARRISH, FL',
    Quote: 'I look forward to trying everything, but have only had 1 pack so far :)
    Thank you for employing Excellent Customer Service staff!! Todd was a big help!!',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Robert V.',
    Location: 'ENGLEWOOD, FL',
    Quote: 'Love your beef. I grew up in Wisconsin and spent 8 years of adult life in Omaha so I
    know good beef(Omaha steaks are not it).',
    Avatar: 'sprite-male',
  },
  {
    Person: 'Natalie K.',
    Location: 'SEDONA, AZ',
    Quote: 'Please send Chicken Thighs (boneless skin-on) & Porterhouse
    Steaks & Hamburger Patties in EVERY shipment. They are awesome!!',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Megan B.',
    Location: 'ALBANY, OR',
    Quote: 'We love SteakChop! Our favorite cuts are the Steak and Fish,
    but we have enjoyed trying other things!',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Greg B.',
    Location: 'JEFFERSON CITY, MO',
    Quote: 'What I have received and cooked has been awesome.
    The pork chops were the best I have ever had.',
    Avatar: 'sprite-male',
  },
  {
    Person: 'Anne H.',
    Location: 'RONKONKOMA, NY',
    Quote: 'We loved the pork chops with the bone from last shipment.
    The filet roast was awesome as well.',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Pam H.',
    Location: 'SPRINGFIELD, OR',
    Quote: 'I referred 2 people this month.
    I love everything I have ever received from Steak Chop.',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Betsy S.',
    Location: 'SUBLIMITY, OR',
    Quote: 'I love all my orders choppy. You’re doing fantastic. I can’t wait for my next order.',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Beverly T.',
    Location: 'GAMBRILLS, MD',
    Quote: 'Thank you for your great customer service! The 13 oz ribeye is my favorite.',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Louils J.',
    Location: 'SOUTHAMPTON, NY',
    Quote: 'We have been delighted with all foods sent to us.... Loved the crab cakes',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Sandra D.',
    Location: 'SPARKS, NV',
    Quote: 'Delicious!!!! The bone-in pork chops were the best ever! Yum. Thank you!!',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Laura B.',
    Location: 'CAPE MAY, NJ',
    Quote: 'Everything is delicious...thank you for having a variety of choices.',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Colleen B.',
    Location: 'SALEM, OR',
    Quote: 'I have been pleased with every selection you have sent. Thank you',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Cynthia G.',
    Location: 'SAINT PAUL, MN',
    Quote: 'Love the service, the meats we have ordered have been awesome.',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Felicia F.',
    Location: 'GREENWOOD, IN',
    Quote: 'I would like to try a variety of everything, loving the STEAK!',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Diane L.',
    Location: 'NEW YORK, NY',
    Quote: 'The shrimp were great. ...We\'re very happy with the selections',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Kevin T.',
    Location: 'ERIE, CO',
    Quote: 'I got the Bistro cut, it was fantastic. My favorite cut yet!',
    Avatar: 'sprite-male',
  },
  {
    Person: 'Margie F.',
    Location: 'WHITE HAVEN, PA',
    Quote: 'There’s a variety to pick from - I love all that I picked!',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Debra W.',
    Location: 'GRAND PRAIRIE, TX',
    Quote: 'Everything has been wonderful. The quality is excellent.',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Trisha K.',
    Location: 'WATSONVILLE, CA',
    Quote: 'The flavor of the rib eyes and T-bone were phenomenal!',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Steve T.',
    Location: 'NORTHPORT, NY',
    Quote: 'Very happy with the quality... Keep up the good work!',
    Avatar: 'sprite-male',
  },
  {
    Person: 'Jason H.',
    Location: 'COLLEGE STATION, TX',
    Quote: 'The filet tenderloin roast is freekin\' amazing!!!',
    Avatar: 'sprite-male',
  },
  {
    Person: 'Clarice O.',
    Location: 'CORVALLIS, OR',
    Quote: 'I have loved everything I have received. ... :~)',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Kelly D.',
    Location: 'NEW BEDFORD, MA',
    Quote: 'Fast delivery, well packaged quality meats.',
    Avatar: 'sprite-female',
  },
  {
    Person: 'TONI S.',
    Location: 'CHICAGO, IL',
    Quote: 'Love the lamb chops..very fun and fancy...',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Cheryl R.',
    Location: 'CLINTON, CT',
    Quote: 'LOVE LOVE LOVE our order every month. :)',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Becky M.',
    Location: 'AIKEN, SC',
    Quote: 'The ribeyes were best we\'ve ever had.',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Julie B.',
    Location: 'WOODSTOCK, GA',
    Quote: 'Everything is yummy! Excellent job!',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Paul D.',
    Location: 'MANAHAWKIN, NJ',
    Quote: 'Thank you. You\'re all the best!!!!',
    Avatar: 'sprite-male',
  },
  {
    Person: 'Jamey T.',
    Location: 'OAKLAND, CA',
    Quote: '... love the shrimp and scallops!',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Maria L.',
    Location: 'CONCORD, CA',
    Quote: 'Recommending to all my friends!',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Beth S.',
    Location: 'MC DONALD, TN',
    Quote: 'Very happy with your product!',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Jen M.',
    Location: 'GREENACRES, WA',
    Quote: 'Your products are AMAZING!!',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Susan W.',
    Location: 'ANDOVER, NJ',
    Quote: 'Absolutely loving the beef',
    Avatar: 'sprite-female',
  },
  {
    Person: 'Lynn E.',
    Location: 'HAMILTON, MI',
    Quote: 'DELICIOUS!!!!!',
    Avatar: 'sprite-female',
  }, */
]

export default allQuotes
