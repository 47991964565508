





















import Vue from 'vue'
import { Swiper as SwiperClass, Autoplay, SwiperOptions } from 'swiper/core'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import 'swiper/swiper-bundle.css'
import { Component } from 'vue-property-decorator'
import allQuotes from '../../../static/quotes'

SwiperClass.use([Autoplay])
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)
@Component({ components: { Swiper, SwiperSlide } })
export default class Quotes extends Vue {
  // eslint-disable-next-line @typescript-eslint/ban-types
  private theQuotes: Array<object> = []

  get quotes() {
    return this.theQuotes
  }

  get swiper(): SwiperClass {
    return (this.$refs.quoteSwiper as any).$swiper
  }

  handleSwiperReadied() {
    const newQuotes = this.getRandom(allQuotes, 11)
    newQuotes.forEach(p => this.theQuotes.push(p))
    this.swiper.update()
    this.swiper.updateSlides()
  }

  // eslint-disable-next-line class-methods-use-this
  getRandom(arr: any, n: number) {
    const result = new Array(n)
    let len = arr.length
    let r = n
    const taken = new Array(len)
    if (n > len) {
      throw new RangeError('getRandom: more elements taken than available')
    }
    // eslint-disable-next-line no-plusplus
    // eslint-disable-next-line no-param-reassign
    // eslint-disable-next-line no-plusplus
    while (r--) {
      const x = Math.floor(Math.random() * len)
      result[r] = arr[x in taken ? taken[x] : x]
      // eslint-disable-next-line no-plusplus
      taken[x] = --len in taken ? taken[len] : len
    }
    return result
  }

  swiperOptions: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 0,
    height: 700,
    loop: true,
    breakpoints: {
      600: {
        slidesPerView: 3,
        spaceBetween: 15,
        height: 1000
      }
    },
    autoplay: { delay: 5000, disableOnInteraction: true },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      dynamicBullets: true
    }
  }
}
